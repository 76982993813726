import React from 'react'
import './CookiePolicy.css'
import { Helmet } from 'react-helmet';
import Navbar from '../../Navbar';
import HomeFooter from '../../HomeFooter/HomeFooter';

export const CookiePolicy = () => {
  return (
    <div className='cookie-policy-wrapper'>
    <div className='cookie-policy-nav'>
    <Navbar />
    <Helmet>
    <title>Cookie Policy</title>
    <meta name="description" content="Cookie Policy" />
    </Helmet>
  </div>

  <div className='cookie-policy-container'>
  <h1>Cookie Policy</h1>

    <div className='cookie-policy-text'>

        

        <div className='cookie-policy-cookie-notice-section'>


            <div className='cookie-policy-cookie-notice-para1'>
                <p>This Cookie Notice, applicable to DLUX TECH CORP PTY LTD ("DLUX," "we," "us," or "our"),
                informs you about our use of cookies and similar technologies across<br/> our websites,
                applications, and services ("Service"). To learn more about how we handle personal
                information obtained through our Service, please refer to <br/>our <a href="/privacy-policy">Privacy Policy</a>.
                </p>

            </div>
        
            <div className='cookie-policy-cookie-notice-para2'>

                <p>Upon your initial access to our Services in certain jurisdictions, you may receive a notification
                regarding the use of cookies and similar technologies. By clicking<br/> "accept cookies," you
                indicate your understanding and agreement with the utilization of these technologies, as
                outlined in this Cookie Notice.
                </p>

            </div>

            <div className='cookie-policy-cookie-notice-para3'>

                <p>It's important to note that accepting cookies is not mandatory, and you can withdraw your
                 consent at any time (refer to "<a href="#control-cookies">How to Control Cookies</a>" below). You<br/> have the option to adjust
                 your browser settings to reject or limit cookies and delete them from your device whenever
                  you choose. However, declining or<br/> deleting our cookies may result in certain areas of our
                  Services taking longer to function or experiencing functionality issues.</p>
            </div>

            <div className='cookie-policy-cookie-notice-para4'>

                <p>Periodically, we may amend or update this Cookie Notice or our information handling
                 practices. In such cases, the revised Cookie Notice will be made available<br/> on our website
                 <a href="https://www.dluxtech.com"> www.dluxtech.com</a> (our Websites).
                </p>

            </div>

        
        </div>


        <div className='cp-whatrcookies-section'>
            <h3>What Are Cookies</h3>

            <div className='cp-whatrcookies-para1'>
                <p>Cookies are small files containing a series of characters that we may place on your computer or mobile device when you access our Services.<br/> 
                These cookies enable us to identify your browser when you visit our Services. While cookies can store preferences and other information, they<br/>
                 are unable to read data from your hard disk or read cookie files generated by other sites. </p>

            </div>


            <div className='cp-whatrcookies-para2'>
                <p>Cookies set by the owner of a website, in this instance, DLUX, are referred to as "first-party cookies." On the other hand, cookies set by entities<br/>
                 other than the website owner are known as "third-party cookies." Third-party cookies facilitate the provision of third-party features or<br/>
                 functionalities within the Service, such as advertising, social media functions, and analytics.</p>
            </div>

        </div>   


        <div className='cp-usecookies-section'>

            <h3>Why We Use Cookies</h3>

            <div className='cp-usecookies-para1'>
                <p>Some cookies are necessary for the operation of the DLUX service: we refer to these as “essential cookies.” For example, a session cookie is<br/>
                used to maintain a user’s session while they are logged into the DLUX TECH web application. </p>
             </div>

            <div className='cp-usecookies-para2'>
                <p>We use tracking tools for many other reasons, including to engage in interest-based advertising. Interest-based advertising or “online<br/> behavioral advertising” is serving advertising content to you based on information gathered from you over time across multiple sites or other<br/> platforms. It includes serving ads to you after you leave our website, encouraging you to return. It also includes serving ads we think are<br/> relevant based on your browsing habits or online activities. These ads may be served on websites. They may also be served in emails. We may<br/> serve these ads, or third parties may serve ads about our products or services.</p>
            </div>

            <div className='cp-usecookies-para3'>
                <p>We may also use tracking tools:</p>
                <ul>
                    <li><p>To recognize new or past website visitors.</p></li>
                    <li><p>To improve our website and to better understand your visits on our platforms.</p></li>
                    <li><p>To integrate with third party social media websites.</p></li>
                    <li><p>To observe your behaviors and browsing activities over time across multiple websites or other platforms.</p></li>
                    <li><p>To better understand the interests of our customers and our website visitors.</p></li>
                    
                </ul>
            </div>  
        
        </div> 


        <div className='cp-cookieswe-section'>

            <h3>The Cookies We Use</h3>

            <div className='cp-cookieswe-para1'>
                <p>The utilization of cookies in our Services serves to enhance the user experience by, among other things, preserving your preferences. Cookies<br/>
                 may also be employed to provide content tailored to your interests. In some cases, our cookies enable us to link your usage of our Services to <br/>
                 personal information you have previously provided.</p>
            </div>

            <div className='cp-cookieswe-para2'>
                <p>The data gathered through cookies allows us to conduct statistical analyses of our Services'
                 usage, facilitating the enhancement and<br/> customization of our content and offerings. It's
                 important to note that any information disclosed to third parties based on cookie data is
                  done on<br/> an aggregated basis, without the utilization of personally identifiable information.</p>
            </div>


            <div className='cp-cookieswe-para3'>
                <p>When you access our Services, you implicitly agree to the collection and use of your information by third parties. We recommend reviewing<br/>
                 their privacy policies and reaching out to them directly for answers to any questions you may have.</p>
            </div>

            <div className='cp-cookieswe-para4'>
                <h4>Strictly Necessary Cookies:</h4>
                <p>These are cookies required for our Services' operation. They include, for example, cookies that enable you to log into secure areas of our<br/>
                 Services. <br/>
                 How to refuse: These cookies are strictly necessary to deliver the Services and therefore cannot be refused. They can be deleted or blocked<br/>
                 using your browser settings (see, <a href="#control-cookies">How to Control Cookies</a>). </p>
            </div>

            <div className='cp-cookieswe-para5'>
                <h4>Performance and Functionality Cookies:</h4>
                <p>These are cookies used to recognise you when you return to our Services. This enables us to personalise our content for you and remember<br/>
                 your preferences (for example, your choice of language or region), but are non-essential to the performance of the Service.<br/>
                 How to refuse: These cookies can be deleted or blocked using your browser settings (see, <a href="#control-cookies">How to Control Cookies</a>).</p>
            </div>

            <div className='cp-cookieswe-para6'>
                <h4>Analytical Cookies (Google Analytics):</h4>
                <p>These cookies allow us to gather analytics to improve the performance and functionality of our Services. These analytics can include<br/>
                 measurements of the number of users on the Services, how long they stay on the Services for, and what parts of the Services they visit. We use<br/>
                this information to help us to improve the way our Services works, or to personalise our Services to your interests.<br/>
                How to refuse: These cookies can be deleted or blocked using your browser settings (see, <a href="#control-cookies">How to Control Cookies</a>). Alternatively, please follow<br/>
                 the links below to opt out: <a href="https://tools.google.com/dlpage/gaoptout">Google</a></p>

            </div>

            <div className='cp-cookieswe-para7'>
                <h4>Tracking Cookies:</h4>

                <div className='cp-cookieswe-para7-sub1'>
                <p>These cookies are used to make the advertising displayed on the Services more relevant to
                 you. These cookies prevent ads from reappearing<br/> and ensure that ads are properly displayed. Certain third-party cookies may track users over different websites and will provide ads relevant to<br/>
                 your interests.</p>
                 </div>

                 <div className='cp-cookieswe-para7-sub2'>
                 <p>How to refuse: These cookies can be deleted or blocked using your browser settings (see,<br/>
                 <a href="#control-cookies">How to Control Cookies</a>, below).</p>
                 </div>

                 <div className='cp-cookieswe-para7-sub3'>
                  <p>In addition, you can exercise advertising cookie choices by visiting:</p>
                  <ul>
                    <li><p><a href="https://optout.aboutads.info/?c=2&lang=EN">YourAdChoices,</a></p></li>
                    <li><p><a href="https://optout.networkadvertising.org/?c=1">Network Advertising Initiative, or</a></p></li>
                    <li><p><a href="http://youronlinechoices.com/">Your Online Choices</a></p></li>
                </ul>

                </div>

            </div>

            <div className='cp-cookieswe-para8'>
                <h4>Social Media Cookies:</h4>

                <p>These cookies allow us to integrate social media functions into our Services and may also be used for advertising purposes. </p>
                <p>How to refuse: These cookies can be deleted or blocked using your browser settings (see, <a href="#control-cookies">How to Control Cookies</a>, below).</p>
            </div>
                
        </div>

        <div id="control-cookies" className='cp-controlcookies-section'>
            <h3>How to Control Cookies</h3>

            <div className='cp-controlcookies-para1'>
                <p>Upon your initial access to our Services in specific jurisdictions, you will receive a notification informing you about the use of cookies<br/>
                and similar technologies. By clicking "accept cookies," you indicate your understanding and agreement with the described use of these technologies<br/>
                in this Cookie Notice. </p>
            </div>


            <div className='cp-controlcookies-para2'>
                <p>It's essential to note that accepting cookies is not mandatory, and you can withdraw your consent at any time, though certain features of our<br/>
                 Services may become unavailable. This can be done by adjusting your browser settings to either reject all cookies or selectively block them.</p>
            </div>

            <div className='cp-controlcookies-para3'>
                <p>For guidance on adjusting settings in popular browsers, refer to the following links:</p>
                <ul>
                    <li><p><a href="https://support.microsoft.com/en-gb/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d">Microsoft Internet Explorer</a></p></li>
                    <li><p><a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en">Google Chrome</a></p></li>
                    <li><p><a href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US">Mozilla Firefox</a></p></li>
                    <li><p><a href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac">Apple Safari</a></p></li>
                </ul>
            </div>

            <div className='cp-controlcookies-para4'>
                <p>To prevent information collection by tracking technologies, you can activate the "Do Not Track" feature in your Google Chrome browser.<br/>
                 Additionally, you can manage advertising cookie preferences by visiting:</p>
                <ul>
                <li><p><a href="https://optout.aboutads.info/?c=2&lang=EN">YourAdChoices,</a></p></li>
                    <li><p><a href="https://optout.networkadvertising.org/?c=1">Network Advertising Initiative, or</a></p></li>
                    <li><p><a href="http://youronlinechoices.com/">Your Online Choices</a></p></li>
                </ul>
            </div>
        </div>

        <div className='cp-webbeacons-section'>
            <h3>Web Beacons:</h3>

            <div className='cp-webbeacons-para1'>
                <p>In addition to utilizing cookies, we employ other technologies to identify and monitor visitors to our Services. A web beacon, also referred to as<br/>
                a "tracking pixel" or "clear GIF," is a transparent graphic image, usually a one-pixel tag, delivered through a web browser or HTML email, often<br/>
                in conjunction with a cookie. </p>
            </div>


            <div className='cp-webbeacons-para2'>
                <p>Web beacons enable us to, for instance, observe the navigation of users between different
                pages within our Services, track access to our <br/>communications, ascertain whether users
                arrived at our Services through an online advertisement displayed on a third-party website,<br/>
                measure the viewing of ads, and enhance the performance of our Services.</p>
            </div>

        </div>

        <div className='cp-targetedadvertising-section'>
            <h3>Targeted Advertising:</h3>

            <div className='cp-targetedadvertising-para1'>
                <p>Kindly be aware that third parties, including advertising networks and providers of external services such as web traffic analysis, utilize cookies<br/>
                 beyond our control. These cookies are typically categorized as analytical/performance or targeting cookies. These entities may leverage<br/>
                information about your visits to our website and others to deliver pertinent advertisements for products and services that align with your<br/>
                 interests. Additionally, they may employ technology to assess the effectiveness of advertisements. </p>
            </div>
        </div>

        <div className='cp-contactus-section'>
            <h3>Contact Us</h3>
            <div className='cp-contactus-para1'>
                <p>If you have questions about this Notice, or the way DLUX collects, uses or otherwise processes your personal information, including the<br/>
                 transfer or onward transfer of your personal information outside your jurisdiction of residence, please contact us at: </p>

            <div className='cp-contactus-para2'>
                 <p>Privacy Team,</p>
                 <p>DLUX TECH CORP PTY LTD</p>
                 <p>28 Beacon Drive, Schofields NSW 2762</p>
                 <p>Email address: <a href="mailto:privacy@dluxtech.com">privacy@dluxtech.com</a></p>
            </div>
            </div>
        </div>

    </div>


  </div>

  <div className='cp-footer'>
    <HomeFooter />
  </div>
  
  </div>
  )
}
