import React from 'react';
import ServicesOne from "./ServicesOne";
function ServicesPage()
{
    return (
    <>
    <ServicesOne/>
    </>
    );
}
export default ServicesPage;