import React from 'react';
import '../../App.css';
import Navbar from '../Navbar';
import RetailConsumer from '../contents/Retail_Consumer/Retail_Consumer';
export default function Industries() {
  return (
    <>
    <Navbar />
    <RetailConsumer/>
    </>
  );
}