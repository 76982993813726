import React from 'react';
import Navbar from '../Navbar';
import AboutUsPage from '../contents/AboutUsPage'



export default function AboutUs() {
  return <><Navbar />
 <AboutUsPage/>
  </>
  
}