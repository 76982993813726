import React from 'react';
import PartnershipOne from './PartnershipOne';

function Partners(){
    return(
     <>
        <PartnershipOne/>     
     </>
    );
}
export default Partners;